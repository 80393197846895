/*Form field Types*/
export const TEXT = "text";
export const TEXT_AREA = "textarea";
export const DROPDOWN = "dropdown";
export const FILE = "file";
export const BOOLEAN = "boolean";
export const MOBILE = "mobile";
export const PHONE = "phone";
export const DATE_PICKER = "datePicker";
export const INFO = "info";
export const lbl_ORIGINAL_MESSAGE="Original Message";
export const lbl_LAST_MESSAGE="Last Message";
export const lbl_FULL_MESSAGE="Full Message";
export const lbl_CLOSING_EMAIL_MESSAGE="Closing email";

/*End Form field Types*/
export const SCHOOL_OFFICE_TYPES=["school","schools","nspira-s","rnet-schools"];
export const COLLEGE_OFFICE_TYPES=["college","colleges","nspira-c","rnet-colleges","nspira-cc","narius-cc","nspira-civils","jr colleges","coaching centre","cc"];
export const ADMIN_OFFICE_TYPES = ["nspira-co", "nspira-cc-co", "nspira-zo", "zonal office", "nguide-ro", "nguide", "cbd", "central office", "co", "nspira-press", "nspira-wh", "nguide-co", "nspira-campaigning", "campaigning", "civil services","rnet","printing press"]

/*SLA Days*/
export const SLA_PLAN_1DAY="1 Day";
export const SLA_PLAN_2DAY="2 Day";
export const SLA_PLAN_3DAY="3 Day";
export const SLA_PLAN_4DAY="4 Day";
export const SLA_PLAN_5DAY="5 Day";
export const SLA_PLAN_6DAY="6 Day";
export const SLA_PLAN_7DAY="7 Day";
export const SLA_PLAN_8DAY="8 Day";
export const SLA_PLAN_9DAY="9 Day";
export const SLA_PLAN_10DAY="10 Day";
export const SLA_PLAN_11DAY="11 Day";
export const SLA_PLAN_12DAY="12 Day";
export const SLA_PLAN_13DAY="13 Day";
export const SLA_PLAN_14DAY="14 Day";
export const SLA_PLAN_15DAY="15 Day";
export const SLA_PLAN_16DAY="16 Day";
export const SLA_PLAN_17DAY="17 Day";
export const SLA_PLAN_18DAY="18 Day";
export const SLA_PLAN_19DAY="19 Day";
export const SLA_PLAN_20DAY="20 Day";
export const SLA_PLAN_21DAY="21 Day";
export const SLA_PLAN_22DAY="22 Day";
export const SLA_PLAN_23DAY="23 Day";
export const SLA_PLAN_24DAY="24 Day";
export const SLA_PLAN_25DAY="25 Day";
export const SLA_PLAN_26DAY="26 Day";
export const SLA_PLAN_27DAY="27 Day";
export const SLA_PLAN_28DAY="28 Day";
export const SLA_PLAN_29DAY="29 Day";
export const SLA_PLAN_30DAY="30 Day";

/**UserSettings Types */
export const MY_TICKETS_FILTERS="MY_TICKETS_FILTERS";
export const ESCALATED_TICKETS_FILTERS="ESCALATED_TICKETS_FILTERS";
export const TRANSFER_TICKETS_FILTERS="TRANSFER_TICKETS_FILTERS";
export const ALL_TICKETS_FILTERS="ALL_TICKETS_FILTERS";
export const MY_TEAMS_TICKETS_FILTERS="MY_TEAMS_TICKETS_FILTERS";
export const ANALYTICS_FILTERS="ANALYTICS_FILTERS";

/**UserSettings Names */
export const TICKET_STATUS="TICKET_STATUS";
export const TICKET_CREATED_DATE_RANGE="TICKET_CREATED_DATE_RANGE";
export const TICKET_DEPARTMENT_NAME="TICKET_DEPARTMENT_NAME";
export const TICKET_HELPTOPIC_NAME="TICKET_HELPTOPIC_NAME";
export const TICKET_ASSIGNEE_NAME="TICKET_ASSIGNEE_NAME";
export const TICKET_OVERDUE="TICKET_OVERDUE";
export const TICKET_CLOSED_DATE_RANGE="TICKET_CLOSED_DATE_START_RANGE";
export const TEAM_NAME="TEAM_NAME";
export const TEAM_LEAD_NAME="TEAM_LEAD_NAME";
export const DEPARTMENT_ASSOCIATED_TEAM_NAME="DEPARTMENT_ASSOCIATED_TEAM_NAME";
export const TEAM_ASSOCIATED_AGENT_NAME="TEAM_ASSOCIATED_AGENT_NAME";

export const LBL_HELPDESK_EMAIL="helpdesk@narayanagroup.com";
export const LBL_CLEAR_FILTER_MESSAGE="Filters cleared succesfully";
export const LBL_6_MONTHS_DATE_RANGE_MESSAGE="Please select data range within 6 months";

/*Version No*/
export const APPLICATION_VERSION_NUMBER="1.0.0.9";
